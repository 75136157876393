import { Box } from "@mui/material";
import { DataGridPro, GridColDef } from "@mui/x-data-grid-pro";
import { integerToMonth } from "../../../helpers/helper";
import { Project } from "../../../models/project.model";
import { ProgressBar } from "../../shared/ui/ProgressBar";

export function ProjectSingleUtilization(props: {project: Project}) {
    const { project } = props;

    const columns: GridColDef[] = [
        {
            field: "year",
            headerName: "Year",
            editable: false,
            sortable: true,
            filterable: false,
            flex: 1
        },
        {
            field: "month",
            headerName: "Month",
            editable: false,
            sortable: true,
            filterable: false,
            flex: 1,
            renderCell: (params: any) => integerToMonth(params.row.month) ?? ""
        },
        {
            field: "hours",
            headerName: "Hours",
            editable: false,
            sortable: true,
            filterable: false,
            flex: 1
        },
        {
            field: "target",
            headerName: "Target",
            editable: false,
            sortable: true,
            filterable: false,
            flex: 1
        },
        {
            field: "utilization",
            headerName: "Utilization",
            editable: false,
            sortable: false,
            filterable: false,
            flex: 1,
            renderCell: (params: any) => (<Box sx={{ width: '100%' }}><ProgressBar value={params.row.target ? ((100 * params.row.hours / params.row.target) > 100 ? 100 : (100 * params.row.hours / params.row.target)) : 100}/></Box>)
        },
    ];

    return (
        <Box sx={{ width: '100%', height: "83vh", maxHeight: "83vh" }}>
            <DataGridPro
                rows={project.utilizations}
                columns={columns}
                rowCount={project.utilizations.length}
                getRowId={(row: any) => row.year + row.month + Math.random()}
            />
        </Box>
    )
}