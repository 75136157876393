import { Button, InputAdornment, TextField } from "@mui/material";
import { useContext, useState } from "react";
import { Project } from "../../models/project.model";
import { SimpleModal } from "../shared/ui/modals/SimpleModal";
import { validateResponse } from "../../services/fetch.service";
import { AuthContext } from "../../contexts/AuthContext";
import toast from "react-hot-toast";
import { createProject } from "../../services/project.service";

export function AddNewProject(props: {open: boolean, setOpen: React.Dispatch<React.SetStateAction<boolean>>, addProject: (project: Project) => Promise<void>}) {
    const {isAuthenticated, setAuthenticated } = useContext(AuthContext);

    const formDefault = {
        name: "",
        retainer: false,
        hourlyRate: 0,
        monthlyRate: 0,
        monthlyHours: 0,
        note: ""
    } as Project;

    const [form, setForm] = useState<Project>(formDefault);

    const submit = async(e: any) => {
        e.preventDefault();
        const response = validateResponse(await createProject(form), setAuthenticated);
        try {
            if(!response.error) {
                props.addProject({...form, id: response.projectId, priority: 0, hours: 0, due: 0} as Project);
                props.setOpen(false);
                toast.success("Project successfully added.");
                setForm(formDefault);
            } else {
                toast.error("Project couldn't be added.");
            }
        } catch(e: any) {
        }
    }

     return (
        <SimpleModal open={props.open} setOpen={props.setOpen}>
            <form onSubmit={submit}>
                <h3>New Project</h3>  
                <div className="mt-4">
                    <TextField
                        fullWidth
                        label="Project name"
                        multiline
                        required
                        maxRows={4}
                        autoFocus
                        value={form.name}
                        onChange={(event: any) => setForm({...form, name: event.target.value})}
                    />
                </div>
                <div className="mt-3 flex gap">
                    <TextField
                        sx={{ width: "120px"}}
                        label="Hourly rate"
                        type='number'
                        value={Number(form.hourlyRate).toString()}
                        onChange={(event: any) => setForm({...form, hourlyRate: event.target.value === "" ? 0 : Number(event.target.value)})}
                        InputProps={{
                            startAdornment: <InputAdornment position="start">$</InputAdornment>,
                        }}
                    />
                    <TextField
                        sx={{ width: "120px"}}
                        label="Monthly rate"
                        type='number'
                        value={Number(form.monthlyRate).toString()}
                        onChange={(event: any) => setForm({...form, monthlyRate: event.target.value === "" ? 0 : Number(event.target.value)})}
                        InputProps={{
                            startAdornment: <InputAdornment position="start">$</InputAdornment>,
                        }}
                    />
                    <TextField
                        sx={{ width: "120px"}}
                        label="Monthly hours"
                        type='number'
                        value={Number(form.monthlyHours).toString()}
                        onChange={(event: any) => setForm({...form, monthlyHours: event.target.value === "" ? 0 : Number(event.target.value)})}
                    />

                </div>
                <div className="mt-5 flex gap">
                    <TextField
                        label="Note"
                        multiline
                        rows={4}
                        fullWidth
                        value={form.note}
                        onChange={(event: any) => setForm({...form, note: event.target.value})}
                    />
                </div>
                <div className="flex end gap-small mt-4">
                    <Button className="" variant="outlined" color="secondary" onClick={() => props.setOpen(false)}>CANCEL</Button>
                    <Button className="" variant="contained" color="primary" type="submit" disabled={!form.name.length}>SAVE PROJECT</Button>
                </div>
            </form>
        </SimpleModal>
    )
}