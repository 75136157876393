import React, { useEffect } from "react";
import { authStatus } from "../services/auth.service";

interface IAuthContext {
    isAuthenticated: Boolean;
    setAuthenticated: (state: boolean) => void;
    isQuickbooksAuthenticated: Boolean;
    setQuickbooksAuthenticated: (state: boolean) => void;
}

const AuthContextDefault = {
    isAuthenticated: authStatus().authenticated,
    setAuthenticated: () => {},
    isQuickbooksAuthenticated: authStatus().quickbooksAuthenticated,
    setQuickbooksAuthenticated: () => {}
}

export const AuthContext = React.createContext<IAuthContext>(AuthContextDefault);