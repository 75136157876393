import { TextField } from "@mui/material";
import { GridRenderCellParams, useGridApiContext } from "@mui/x-data-grid";
import { LocalizationProvider, DatePicker } from "@mui/x-date-pickers";
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import dayjs, { Dayjs } from 'dayjs';
import { useState } from "react";
import { PatchModel } from "../../../../models/patch.model";

export function RenderDate(props: {params: GridRenderCellParams<Date>, updateDate: (param: PatchModel) => Promise<void>}) {
    const { id, value, field } = props.params;
    const [valueCopy, setValueCopy] = useState<Dayjs | null>(dayjs(value));

    return (
      <LocalizationProvider dateAdapter={AdapterDayjs}>
        <DatePicker
          label=""
          value={valueCopy}
          onChange={(newValue: any) => {
            if(!newValue) return;
            setValueCopy(newValue);
            const newDate = dayjs(newValue);
            if(dayjs(value) ===  newDate) return;    
            props.updateDate({ id, field, value: newDate?.toDate() } as PatchModel);
          }}
          renderInput={(params) => <TextField  size="small" variant="standard" {...params} />}
        />
      </LocalizationProvider>
    );
} 