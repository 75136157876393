import { Alert } from "@mui/material";
import { useEffect, useState } from "react";
import { WarningMessage } from "../../models/warningMessage.model";
import { SimpleModal } from "../shared/ui/modals/SimpleModal";
import { SignIn } from "./SignIn";
import { SignUp } from "./SignUp";
import logo from "../../static/assets/logo.png";

export function ConnectModal(props: {
    open: boolean, 
    setOpen?: React.Dispatch<React.SetStateAction<boolean>> | undefined, 
}) {
    const [isSignIn, setIsSignIn] = useState(true);
    const [topMessage, setTopMessage] = useState({error: false, message: null} as WarningMessage);

    useEffect(() => {
        if(topMessage.error) setTopMessage({error: false, message: null});
    }, [isSignIn]);

    useEffect(() => {
        if(topMessage.message) {
            setTimeout(() => setTopMessage({error: false, message: null}), 3000);
        }
    }, [topMessage]);

    return (
        <SimpleModal open={props.open} background={true} header={<div className="connect-header flex center items-center"><img src={logo} alt="" /></div>}>
            <div>
            {
                topMessage.message !== null &&
                <Alert className="mb-2" variant="outlined" severity={topMessage.error ? 'error': 'success'}>
                    {topMessage.message}
                </Alert>
            }
            {
                isSignIn ? 
                <SignIn setSignUp={setIsSignIn} setWarningMessage={setTopMessage}/> 
                : <SignUp setSignIn={setIsSignIn} setWarningMessage={setTopMessage}/>
                
            }

            </div>
        </SimpleModal>
    )
}