import { Button, Box, IconButton, Tooltip } from "@mui/material";
import { GridSortModel, GridCellParams, GridCellEditCommitParams, GridColDef, GridRenderCellParams } from "@mui/x-data-grid";
import { DataGridPro } from "@mui/x-data-grid-pro";
import { useContext, useEffect, useState } from "react";
import toast from "react-hot-toast";
import { PatchModel } from "../../../models/patch.model";
import { Project } from "../../../models/project.model";
import { SearchModel } from "../../../models/search.model";
import { Task } from "../../../models/task.model";
import { validateResponse } from "../../../services/fetch.service";
import { getTasks, patchTask, patchTaskInvoiced } from "../../../services/task.service";
import { RenderDate } from "../../shared/ui/grid/RenderDate";
import { RenderUser } from "../../shared/ui/grid/RenderUser";
import { ConfirmModal } from "../../shared/ui/modals/ConfirmModal";
import { AddNewTask } from "../../tasks/AddNewTask";
import { EditInvoiced } from "../../tasks/grid/EditInvoiced";
import { RenderInvoiced } from "../../tasks/grid/RenderInvoiced";
import HighlightOffIcon from '@mui/icons-material/HighlightOff';
import InfoIcon from '@mui/icons-material/Info';
import { AuthContext } from "../../../contexts/AuthContext";
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import DeleteIcon from '@mui/icons-material/Delete';
import { TaskFilters } from "../../tasks/TaskFilters";
import { Filter } from "../../../models/filter.model";
import { GlobalHotKeys } from "react-hotkeys";
import { CSVDownload } from "react-csv";
import FileDownloadIcon from '@mui/icons-material/FileDownload';
import { TASK_FILTERS } from "../../../constants";
import { SetAllTasks } from "../../tasks/SetAllTasks";
import { EditTime } from "../../tasks/grid/EditTime";
import { TasksHours } from "../../../models/tasksHours.model";
import ReceiptIcon from '@mui/icons-material/Receipt';
import { authStatus } from "../../../services/auth.service";
import { createInvoice } from "../../../services/project.service";

export function ProjectSingleTasks(props: {project: Project}) {
    const { project } = props;
    const {isAuthenticated, setAuthenticated, isQuickbooksAuthenticated } = useContext(AuthContext);
    const [tasks, setTasks] = useState<Task[]>([]);
    const [tasksHours, setTasksHours] = useState<TasksHours>();

    const [loading, setLoading] = useState<boolean>(false);
    const [total, setTotal] = useState(0);
    const [editCellObj, setEditCellObj] = useState<string>();
    const [initialized, setInitialized] = useState(false);
    const [searchObj, setSearchObj] = useState<SearchModel>({page: 0, rowsPerPage: 20, search: "", orderBy: "date", asc: false})
    const [newTaskModal, setNewTaskModal] = useState<boolean>(false);
    const [selectedObj, setSelectedObj] = useState<any>(null);
    const [selectedFilters, setSelectedFilters] = useState<Filter[]>([]);
    const [exportLoading, setExportLoading] = useState<boolean>(false);
    const [exportData, setExportData] = useState<any>(null);
    const [invoiceLoading, setInvoiceLoading] = useState<boolean>(false);
    const [invoiceClicked, setInvoiceClicked] = useState<any>(null);


    useEffect(() => {
        var defaultFilters: Filter[] = [];
        for(var i = 0; i < TASK_FILTERS.length; ++i) {
            for(var j = 0; j < TASK_FILTERS[i].filters.length; ++j) {
                if(TASK_FILTERS[i].filters[j].default) defaultFilters.push({...TASK_FILTERS[i].filters[j], category: TASK_FILTERS[i].category});
            }
        }
        setSelectedFilters(defaultFilters);

        if(!project?.id) return; 
        retrieveTasks(defaultFilters);
    }, []);

    useEffect(() => {
        if(!initialized) return;
        const timeOutId = setTimeout(() => {setSearchObj({...searchObj, page: 0}); retrieveTasks()}, 500);
        return () => clearTimeout(timeOutId);
    }, [searchObj.search]);

    useEffect(() => {
        if(!initialized) return;
        setSearchObj({...searchObj, page: 0});
        retrieveTasks();
    }, [searchObj.rowsPerPage, searchObj.orderBy, searchObj.asc, selectedFilters]);

    useEffect(() => {
        if(!initialized) return;
        retrieveTasks();
    }, [searchObj.page]);

    const addTask = async(task: Task) => {
        setTasks([task, ...tasks]);
        setTasksHours({totalActual: (tasksHours?.totalActual ?? 0) + (task?.timeActual ?? 0), totalInvoicable: (tasksHours?.totalInvoicable ?? 0) + (task?.time ?? 0)});
    }

    const retrieveTasks = async(defaultFilters: Filter[] = []) => {
        if(!project.id) return;
        setLoading(true)
        try {
            const response = validateResponse(await getTasks({...searchObj, filters: selectedFilters.concat(defaultFilters).map((filter: Filter) => '&' + filter.field + '=' + filter.value).join("")}, project.id), setAuthenticated);
            if(!response.error) {
                setInitialized(true);
                setTotal(response.total);
                setTasks(response.tasks);
                setTasksHours(response.tasksHours);
            } else {
                console.log('error inside');
            }
            setLoading(false);
        } catch(e) {
            console.log(e);
            setLoading(false)
        }
    }

    const updateTask = async(params: GridCellEditCommitParams) => {
        if(JSON.stringify(params.value) === editCellObj) return;
        try {
            const response = validateResponse(await patchTask(params as PatchModel), setAuthenticated);
            if(!response.error) {
                toast.success(`Task successfully ${params.field === "visible" && !params.value ? 'removed': 'updated'}.`);
            } else {
                toast.error("Error while updating the task.");
            }
        } catch(e) {
            console.log(e);
        }
    }

    const updateHours = async(params: GridCellEditCommitParams) => {
        const index = tasks.findIndex((task: Task) => task.id === params.id);
        if (index === -1) return;

        const newTasks = [...tasks];
        const timeActual = tasks[index]?.timeActual ?? 0;
        const time = tasks[index]?.time ?? 0;
        const { value, field } = params;

        if (field === "visible") {
            setTasksHours({
            totalActual: (tasksHours?.totalActual ?? 0) - timeActual,
            totalInvoicable: (tasksHours?.totalInvoicable ?? 0) - time,
            });
        } else if (field === "timeActual") {
            setTasksHours({
            totalActual: (tasksHours?.totalActual ?? 0) + (value - timeActual),
            totalInvoicable: tasksHours?.totalInvoicable ?? 0,
            });
            newTasks[index].timeActual = value;
        } else {
            setTasksHours({
            totalActual: tasksHours?.totalActual ?? 0,
            totalInvoicable: (tasksHours?.totalInvoicable ?? 0) + (value - time),
            });
            newTasks[index].time = value;
        }

        setTasks(newTasks);    
    }

    const exportTasks = async() => {
        setExportLoading(true);
        try {
            const response = validateResponse(await getTasks({...searchObj, page: 0, rowsPerPage: total, filters: selectedFilters.map((filter: Filter) => '&' + filter.field + '=' + filter.value).join("")}, project?.id), setAuthenticated);
            if(!response.error) {
                setExportData(response.tasks.map((task: Task) => {
                    return ({Description: task.task, Project: task.project?.name, Time: task.time, TimeActual: task.timeActual, Date: task.date, Invoiced: task.invoiced})
                }));
                setTimeout(() => setExportData(null), 500);
            } else {
                console.log('error inside');
            }
            setExportLoading(false);
        } catch(e) {
            console.log(e);
            setExportLoading(false);
        }
    }

    const newInvoice = async() => {
        setInvoiceClicked(null);
        setInvoiceLoading(true);
        try {
            const response = validateResponse(await createInvoice(project.id), setAuthenticated);
            if(!response.error) {
                toast.success("New invoice successfully created");
                retrieveTasks();
            } else {
                toast.error("Invoice could not be created");
            }
            setInvoiceLoading(false);
        } catch(e) {
            setInvoiceLoading(false);
        }
    }

    const updateTasksInvoiced = async(newState: boolean) => {
        try {
            const response = validateResponse(await patchTaskInvoiced(newState, {...searchObj, page: 0, rowsPerPage: total, filters: selectedFilters.map((filter: Filter) => '&' + filter.field + '=' + filter.value).join(""), projectId: project.id}), setAuthenticated);
            if(!response.error) {
                toast.success(`Tasks successfully updated`);
                retrieveTasks();
            } else {
                toast.error("Error while updating the tasks.");
            }
            setSelectedObj(null);
        } catch(e) {
            console.log(e);
            setSelectedObj(null);
        }
    }

    const columns: GridColDef[] = [
        {
            field: "task",
            headerName: "What did you do?",
            editable: true,
            filterable: true,
            flex: 4,
        },
        {
            field: "time",
            headerName: `Invoicable ${tasksHours ? '(' + tasksHours.totalInvoicable?.toFixed(2).replace(/\.00$/, "") + ')' : ''}`,
            editable: true,
            filterable: true,
            flex: 1,
            type: "number",
            renderEditCell: (params: GridRenderCellParams<any, any, any>) => <EditTime {...params} />
        },
        {
            field: "timeActual",
            headerName: `Actual ${tasksHours ? '(' + tasksHours.totalActual?.toFixed(2).replace(/\.00$/, "") + ')' : ''}`,
            editable: true,
            filterable: true,
            flex: 1,
            type: "number",
            renderEditCell: (params: GridRenderCellParams<any, any, any>) => <EditTime {...params} />
        },
        {
            field: "date",
            headerName: "Date",
            editable: false,
            filterable: true,
            flex: 1,
            renderCell: (params: GridRenderCellParams<any, any, any>) => <RenderDate params={params} updateDate={updateTask}/>,
        },
        {
            field: "invoiced",
            headerName: "Invoiced",
            editable: true,
            filterable: true,
            // flex: 1,
            align: 'center',
            renderCell: (params: GridRenderCellParams<any, any, any>) => <RenderInvoiced {...params} />,
            renderEditCell: (params: GridRenderCellParams<any, any, any>) => <EditInvoiced {...params} />
        },
        {
            field: "user",
            headerName: "User",
            editable: false,
            filterable: false,
            sortable: false,
            renderCell: (params: GridRenderCellParams<any, any, any>) => <RenderUser {...params}/>,
        },
        {
            field: "edit",
            headerName: "",
            editable: false,
            sortable: false,
            filterable: false,
            disableColumnMenu: true,
            renderCell: (params: GridRenderCellParams<any, any, any>) => {
              return (
                <div key={params.row.id} className='flex gap-small'>      
                    <IconButton aria-label="delete" onClick={() => setSelectedObj(params.row)}>
                      <DeleteIcon />
                    </IconButton>
                </div>
              )
            }
        }
    ];

    return (
        <GlobalHotKeys
            keyMap={{
                FOCUS_BARCODE: "ctrl+enter"
            }}
            handlers={{
                FOCUS_BARCODE: () => setNewTaskModal(true)
            }}
        >                        
            <div className="mx-3 my-3 flex items-center between">
                <Tooltip title={'Ctrl + Enter'} placement="right">
                    <Button size='medium' variant="contained" color="primary" startIcon={<AddCircleOutlineIcon />} onClick={() => setNewTaskModal(true)}>
                        NEW TASK
                    </Button>
                </Tooltip>
                <TaskFilters selectedFilters={selectedFilters} setSelectedFilters={setSelectedFilters} />
            </div>
            <AddNewTask open={newTaskModal} project={project} setOpen={setNewTaskModal} addTask={addTask}/>
            <ConfirmModal 
                    selectedObj={selectedObj} 
                    setSelectedObj={setSelectedObj} 
                    icon={<HighlightOffIcon color="error" sx={{ fontSize: "50px" }}/>} 
                    title="Confirm Delete"
                    message="Are you sure you want to delete the selected task?"
                    buttonText='Delete'
                    onConfirm={() => {
                        updateTask({id: selectedObj.id, field: "visible", value: false} as PatchModel); 
                        updateHours({id: selectedObj.id, field: "visible", value: false} as PatchModel);
                        setSearchObj({...searchObj, page: 0});
                        setTasks(tasks.filter((task: Task) => task.id !== selectedObj?.id));
                        setTotal(total - 1);            
                        setSelectedObj(null);
                    }}/>  
            <ConfirmModal 
                    selectedObj={invoiceClicked} 
                    setSelectedObj={setInvoiceClicked} 
                    icon={<InfoIcon color="warning" sx={{ fontSize: "50px" }}/>} 
                    title="Confirm Invoice Creation"
                    message="Are you sure you want to create an invoice?"
                    buttonText='Create'
                    onConfirm={newInvoice}/>  
                
            <Box className="relative" sx={{ width: '100%', height: "76.5vh", maxHeight: "76.5vh" }}>
                <DataGridPro
                    rows={tasks}
                    columns={columns}
                    rowCount={total}
                    page={searchObj.page}
                    loading={loading}
                    pageSize={searchObj.rowsPerPage}
                    onPageSizeChange={(newPageSize: number) => {
                    setSearchObj({...searchObj, page: 0, rowsPerPage: newPageSize})
                    }}
                    disableSelectionOnClick={true}
                    onPageChange={(page: number) => setSearchObj({...searchObj, page: page})}
                    rowsPerPageOptions={[5, 10, 20, 50, 100]}
                    pagination
                    paginationMode="server"
                    sortingMode="server"
                    sortModel={[{field: searchObj.orderBy ?? "", sort: searchObj.asc === true ? 'asc' : 'desc'}]}
                    onSortModelChange={(sortModel: GridSortModel) => {
                        if(sortModel[0] === undefined) {
                            setSearchObj({...searchObj, asc: true})
                        } else {
                            setSearchObj({...searchObj, orderBy: sortModel[0]?.field ?? "date", asc: (sortModel[0]?.sort == "asc" ? true : false)})
                        }
                    }}
                    onCellEditStart={(params: GridCellParams) => setEditCellObj(JSON.stringify(params.value))}
                    onCellEditCommit={(param) => {
                        updateTask(param);
                        updateHours(param);
                    }
                }
                />
                <div className="absolute bottom items-center flex gap">
                    <Button disabled={exportLoading} size='medium' variant="contained" color="primary" startIcon={<FileDownloadIcon />} onClick={exportTasks}>
                            EXPORT
                    </Button>
                    {
                        authStatus().content?.roles?.includes('Administrator') && isQuickbooksAuthenticated && project.quickbooksCustomerId !== null && project.quickbooksCustomerId != 0 &&
                        <Button disabled={invoiceLoading} onClick={() => {setInvoiceClicked(true)}} size='medium' variant="contained" color="primary" startIcon={<ReceiptIcon />}>
                                CREATE INVOICE
                        </Button>
                    }
                    {
                        exportData && <CSVDownload data={exportData} target="_blank" />
                    }
                    <SetAllTasks tasks={tasks} onChange={updateTasksInvoiced} />
                </div>
            </Box>
        </GlobalHotKeys>
    )

}