import { Button, FormControl, InputLabel, Link, MenuItem, Select, TextField } from "@mui/material";
import { useContext, useEffect, useState } from "react";
import { AuthContext } from "../../contexts/AuthContext";
import { isEmail } from "../../helpers/helper";
import { SignUpModel } from "../../models/signup.model";
import { WarningMessage } from "../../models/warningMessage.model";
import { getCompanies } from "../../services/company.service";
import { validateResponse } from "../../services/fetch.service";
import { createUser } from "../../services/user.service";

export function SignUp(props: {setSignIn: React.Dispatch<React.SetStateAction<boolean>>, setWarningMessage: React.Dispatch<React.SetStateAction<WarningMessage>>}) {
    const [form, setForm] = useState({
        firstName: '',
        lastName: '',
        userName: '',
        email: '',
        password: '',
        repeatPassword: '',
        companyCode: ''
    } as SignUpModel);
    const {isAuthenticated, setAuthenticated } = useContext(AuthContext);

    const submit = async(e: any) => {
        e.preventDefault();
        try {
            const response = validateResponse(await createUser(form), setAuthenticated);
            if(!response.error) {
                setForm({
                    firstName: '',
                    lastName: '',
                    userName: '',
                    email: '',
                    password: '',
                    repeatPassword: '',
                    companyCode: ''
                } as SignUpModel);
                props.setWarningMessage({error: false, message: "Account successfully created"});
                props.setSignIn(true);
            } else {
                props.setWarningMessage({error: true, message: response.error});
            }
        } catch(e: any) {
            props.setWarningMessage({error: true, message: e.toString()});
        }
    }

    return (
        <form onSubmit={submit}>
            <div className="flex column mt-4">
                <div className="flex column">
                    <div className="flex between mb-3">
                        <TextField 
                            className="mr-1 w-100" 
                            label="First Name" 
                            variant="outlined" 
                            value={form.firstName} 
                            required={true}
                            onChange={(event: any) => setForm({...form, firstName: event.target.value})}/>
                        <TextField 
                            className="ml-1 w-100" 
                            label="Last Name" 
                            variant="outlined" 
                            required={true}
                            value={form.lastName} 
                            onChange={(event: any) => setForm({...form, lastName: event.target.value})} />
                    </div>
                    <div className="flex between mb-3">
                        <TextField 
                            className="mr-1 w-100" 
                            label="User Name" 
                            variant="outlined" 
                            value={form.userName} 
                            onChange={(event: any) => setForm({...form, userName: event.target.value})} />
                        {/* <FormControl fullWidth>
                            <InputLabel id="company-select">Company</InputLabel>
                            <Select
                                labelId="company-select"
                                value={form.company.id}
                                label="Company"
                                onChange={(event: any) => setForm({...form, company: {id: Number(event.target.value)}})}>
                                {companies.map((company: any, index: number) => <MenuItem key={index} value={company.id}>{company.name}</MenuItem>)}
                            </Select>
                        </FormControl> */}
                        <TextField 
                            className="mr-1 w-100" 
                            label="Company Code" 
                            required
                            variant="outlined" 
                            value={form.companyCode} 
                            onChange={(event: any) => setForm({...form, companyCode: event.target.value})} />
                    </div>
                </div>
                    <TextField  
                        label="Email" 
                        type={"email"} 
                        variant="outlined" 
                        value={form.email} 
                        onChange={(event: any) => setForm({...form, email: event.target.value})} 
                        error={!isEmail(form.email) && form.email?.length > 0} 
                        helperText={ !isEmail(form.email) && form.email?.length > 0 ? 'Please provide a valid email address.' : ''}
                        required={true}
                    />
                <div className="flex column mt-5">
                    <TextField 
                        className="mb-3" 
                        label="Password" 
                        type={"password"} 
                        variant="outlined" 
                        value={form.password} 
                        onChange={(event: any) => setForm({...form, password: event.target.value})} 
                        required={true}
                        autoComplete='new-password'
                        />
                    <TextField 
                        label="Repeat Password" 
                        type={"password"} 
                        variant="outlined" 
                        value={form.repeatPassword} 
                        onChange={(event: any) => setForm({...form, repeatPassword: event.target.value})} 
                        error={form.password !== form.repeatPassword && form.repeatPassword?.length > 0}
                        helperText={ form.password !== form.repeatPassword && form.repeatPassword?.length > 0 ? 'Passwords need to match.' : ''}
                        required={true}
                        autoComplete='new-password'

                        />
                </div>
                <Button 
                    className="mt-4" 
                    variant="contained" 
                    type="submit"
                    disabled={!form.password ||!form.repeatPassword || form.password !== form.repeatPassword || !form.companyCode }
                    >SIGN UP</Button>

                <Link
                    className="mt-4"
                    component="button"
                    variant="body2"
                    onClick={() => {props.setSignIn(true)}}
                >
                    You already have an account? Sign In
                </Link>
            </div>
        </form>
    )
}