import { TextField, InputAdornment, Checkbox, FormControlLabel, FormControl, InputLabel, MenuItem, Select, Button } from "@mui/material";
import { useContext, useState } from "react";
import toast from "react-hot-toast";
import { AuthContext } from "../../../contexts/AuthContext";
import { Project } from "../../../models/project.model";
import { validateResponse } from "../../../services/fetch.service";
import { putProject } from "../../../services/project.service";

export function ProjectSingleInfo(props: {project: Project, setProject: React.Dispatch<React.SetStateAction<Project|undefined>>}) {
    const { project } = props;
    const {isAuthenticated, setAuthenticated } = useContext(AuthContext);
    const [form, setForm] = useState<Project>(project);
    const [hasChanged, setHasChanged] = useState(false);

    const submit = async(e: any) => {
        e.preventDefault();
        const response = validateResponse(await putProject(form), setAuthenticated);
        try {
            if(!response.error) {
                toast.success("Project successfully updated.");
                props.setProject({...project, ...form});
                setHasChanged(false);
            } else {
                toast.error("Project couldn't be updated.");
            }
        } catch(e: any) {
        }
    } 

    return (
        <div className="mx-3 my-3">     
            <form className="" onSubmit={submit} onChange={() => !hasChanged && project?.id && setHasChanged(true)}>
                <div className="flex between gap-big" >
                    <div className="w-50 flex column between">
                        <div className="flex mt-4 between gap">
                            <TextField
                                fullWidth
                                label="Project name"
                                multiline
                                required
                                value={form.name}
                                onChange={(event: any) => setForm({...form, name: event.target.value})}
                            />
                            <FormControlLabel 
                                sx={{'& .MuiSvgIcon-root': { fontSize: 28 }}} 
                                control={<Checkbox defaultChecked={form.active ?? false} />} 
                                label="Active" 
                                value={form.active ?? false}
                                onChange={(event: any, val: boolean) => setForm({...form, active: val})}
                                />
                            <FormControlLabel 
                                sx={{'& .MuiSvgIcon-root': { fontSize: 28 }}} 
                                control={<Checkbox defaultChecked={form.retainer ?? false}/>} 
                                label="Retainer" 
                                value={form.retainer ?? false}
                                onChange={(event: any, val: boolean) => setForm({...form, retainer: val})}
                                />
                        </div>
                        <div className="mt-4">
                            <FormControl fullWidth>
                                <InputLabel id="priority-label">Priority</InputLabel>
                                <Select
                                    labelId="priority-label"
                                    value={form.priority ?? 0}
                                    label="Priority"
                                    defaultValue={0}
                                    onChange={(event: any) => setForm({...form, priority: Number(event.target.value)})}
                                >
                                    <MenuItem value={0}>0</MenuItem>
                                    <MenuItem value={1}>1</MenuItem>
                                    <MenuItem value={2}>2</MenuItem>
                                    <MenuItem value={3}>3</MenuItem>
                                    <MenuItem value={4}>4</MenuItem>
                                </Select>
                            </FormControl>
                        </div>
                        <div className="mt-4 between flex gap">
                            <TextField
                                label="Hourly rate"
                                type='number'
                                fullWidth
                                value={Number(form.hourlyRate).toString()}
                                onChange={(event: any) => setForm({...form, hourlyRate: event.target.value === "" ? 0 : Number(event.target.value)})}
                                InputProps={{
                                    startAdornment: <InputAdornment position="start">$</InputAdornment>,
                                }}
                            />
                            <TextField
                                label="Monthly rate"
                                type='number'
                                fullWidth
                                value={Number(form.monthlyRate).toString()}
                                onChange={(event: any) => setForm({...form, monthlyRate: event.target.value === "" ? 0 : Number(event.target.value)})}
                                InputProps={{
                                    startAdornment: <InputAdornment position="start">$</InputAdornment>,
                                }}
                            />
                            <TextField
                                label="Monthly hours"
                                type='number'
                                fullWidth
                                value={Number(form.monthlyHours).toString()}
                                onChange={(event: any) => setForm({...form, monthlyHours: event.target.value === "" ? 0 : Number(event.target.value)})}
                            />
                        </div>
                        <div className="mt-4">
                            <TextField
                                    fullWidth
                                    label="Note"
                                    multiline
                                    maxRows={10}
                                    rows={7}
                                    value={form.note ?? ""}
                                    onChange={(event: any) => setForm({...form, note: event.target.value})}
                                />  
                        </div>
                    </div>
                    <div className="w-50 flex column between">
                        <div className="mt-4 flex between gap-small">
                            <TextField
                                fullWidth
                                label="Quickbooks Customer Id"
                                value={form.quickbooksCustomerId ?? ""}
                                onChange={(event: any) => setForm({...form, quickbooksCustomerId: Number(event.target.value) ?? null})}
                            />
                            <TextField
                                fullWidth
                                label="PO Number"
                                value={form.po ?? ""}
                                onChange={(event: any) => setForm({...form, po: event.target.value ?? ""})}
                            />
                        </div>
                        <div className="mt-4">
                            <TextField
                                fullWidth
                                label="Main Contact Name"
                                value={form.mainContact ?? ""}
                                onChange={(event: any) => setForm({...form, mainContact: event.target.value})}
                            />
                        </div>
                        <div className="mt-4">
                            <TextField
                                fullWidth
                                label="Main Phone"
                                value={form.mainPhone ?? ""}
                                onChange={(event: any) => setForm({...form, mainPhone: event.target.value})}
                            />
                        </div>
                        <div className="mt-4">
                            <TextField
                                fullWidth
                                label="Main Email"
                                value={form.mainEmail ?? ""}
                                onChange={(event: any) => setForm({...form, mainEmail: event.target.value})}
                            />
                        </div>
                        <div className="mt-4">
                            <TextField
                                fullWidth
                                label="Website"
                                value={form.website ?? ""}
                                onChange={(event: any) => setForm({...form, website: event.target.value})}
                            />
                        </div>
                    </div>
                </div>
                <Button className="mt-4" variant="contained" color="primary" type="submit" disabled={!form.name.length || !hasChanged}>SAVE PROJECT</Button>
            </form>                          
        </div>
    )
}