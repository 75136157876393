import { Button, Divider, ListItemIcon, ListItemText, Menu, MenuItem, Tooltip } from "@mui/material";
import { useNavigate } from "react-router";
import GroupIcon from '@mui/icons-material/Group';
import DashboardIcon from '@mui/icons-material/Dashboard';
import AssignmentIcon from '@mui/icons-material/Assignment';
import FolderIcon from '@mui/icons-material/Folder';
import LogoutIcon from '@mui/icons-material/Logout';
import { useContext, useState } from "react";
import { AuthContext } from "../contexts/AuthContext";
import logo from "../static/assets/logo.png";
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import { authStatus } from "../services/auth.service";
import SettingsIcon from '@mui/icons-material/Settings';
import { MyAccount } from "./account/MyAccount";

export function LeftNav() {
    const navigate = useNavigate();
    const {isAuthenticated, setAuthenticated, isQuickbooksAuthenticated } = useContext(AuthContext);
    const path = window.location.pathname.split('/')[1];
    const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
    const [myAccountModalOpen, setMyAccountModalOpen] = useState(false);

    const open = Boolean(anchorEl);
    
    const API_URL = process.env.REACT_APP_API as String

    const handleClick = (event: any) => {
      setAnchorEl(event.currentTarget);
    };

    return (
        <div className="left-nav flex column between items-center">
            <div className="flex column w-100 ">
                <Button className="home-btn" onClick={() => navigate('/')}>
                    <img src={logo} alt="" />
                </Button>
                {/* <div 
                    className={`menu-link ${!path ? " selected" : ""} `}
                    onClick={() => navigate('/')}>
                    <div>
                        <DashboardIcon/>
                        <p>Dashboard</p>
                    </div>
                </div> */}
                <div 
                    className={`menu-link ${path === "projects" ? " selected" : ""}`}
                    onClick={() => navigate('/projects')}>
                    <div>
                        <FolderIcon/>
                        <p>Projects</p>
                    </div>
                </div>
                <div 
                    className={`menu-link ${path === "tasks" ? " selected" : ""}`}
                    onClick={() => navigate('/tasks')}>
                    <div>
                        <AssignmentIcon/>
                        <p>Tasks</p>
                    </div>
                </div>
                {/* <div 
                    className={`menu-link ${path === "users" ? " selected" : ""}`}
                    onClick={() => navigate('/users')}>
                    <div>
                        <GroupIcon/>
                        <p>Users</p>
                    </div>
                </div> */}
                
            </div>
                <div className="flex column items-center px-4">
                    <Button
                        sx={{borderRadius: "20px", fontSize: "10px"}}
                        startIcon={<AccountCircleIcon />}
                        onClick={handleClick}
                        variant='outlined'
                        color="info"
                    >
                        {(authStatus().content?.firstname + ' ' + authStatus().content?.lastname) ?? "My Account"}
                    </Button>
                    <Menu
                        anchorEl={anchorEl}
                        open={open}
                        onClose={() => setAnchorEl(null)}
                        anchorOrigin={{
                            vertical: 'bottom',
                            horizontal: 'center',
                        }}
                        transformOrigin={{
                            vertical: 'bottom',
                            horizontal: 'center',
                        }}
                        color='primary'
                    >
                       <MenuItem color="secondary" onClick={(event: any) => {setAnchorEl(null); setMyAccountModalOpen(true)}}>
                            <ListItemIcon>
                                <SettingsIcon />
                            </ListItemIcon>
                            <ListItemText>Settings</ListItemText>
                        </MenuItem>
                        <Divider />
                       <MenuItem color="secondary" onClick={(event: any) => {setAnchorEl(null); setAuthenticated(false)}}>
                            <ListItemIcon>
                                <LogoutIcon />
                            </ListItemIcon>
                            <ListItemText>Log out</ListItemText>
                        </MenuItem>
                    </Menu>
                    <MyAccount open={myAccountModalOpen} setOpen={setMyAccountModalOpen}/>
                    {
                        authStatus().content?.roles?.includes('Administrator') &&
                        <>
                            {
                                isQuickbooksAuthenticated ?
                                    <span className="quickbook-status active mt-4 white">QUICKBOOKS</span>
                                :
                                    <a href={`${API_URL}auth/authorization-link`} className="quickbook-status inactive mt-4 white">QUICKBOOKS</a>
                            }
                        </>
                    }
                </div>
        </div>
    )
}