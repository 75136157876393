import { Button, CircularProgress, Link, TextField } from "@mui/material"
import { useContext, useState } from "react"
import { useNavigate } from "react-router-dom";
import { AuthContext } from "../../contexts/AuthContext";
import { isEmail } from "../../helpers/helper"
import { SignInModel } from "../../models/signin.model"
import { WarningMessage } from "../../models/warningMessage.model";
import { signin } from "../../services/auth.service";

export function SignIn(props: { 
    setSignUp: React.Dispatch<React.SetStateAction<boolean>>, 
    setWarningMessage: React.Dispatch<React.SetStateAction<WarningMessage>>, 
}) {
    const { isAuthenticated, setAuthenticated } = useContext(AuthContext);

    const [form, setForm] = useState({username: '', password: '', staySigned: false} as SignInModel);
    const [loading, setLoading] = useState(false);
    const navigate = useNavigate();

    const submit = async(e: any) => {
        e.preventDefault()
        try {
            setLoading(true);
            const response = (await signin(form)).data;
            if(!response.error) {
                setForm({username: '', password: '', staySigned: false} as SignInModel);
                localStorage.setItem('token', response?.token);
                localStorage.setItem('quickbooksTokenExpires', response?.quickbooksTokenExpires);
                setAuthenticated(true);
                navigate("/");
            } else {
                props.setWarningMessage({error: true, message: response.error});
            }
            setLoading(false);
        } catch(e: any) {
            props.setWarningMessage({error: true, message: e.toString()});
            setLoading(false);
        }
    }

    return (
        <form onSubmit={submit}>
            <div className="flex column mt-4">
                <TextField  
                    label="Username" 
                    type={"text"} 
                    variant="outlined" 
                    value={form.username} 
                    onChange={(event: any) => setForm({...form, username: event.target.value})} 
                    required={true}
                />
                <TextField 
                    className="mt-3 mb-4" 
                    label="Password" 
                    type={"password"} 
                    variant="outlined" 
                    value={form.password} 
                    onChange={(event: any) => setForm({...form, password: event.target.value})} 
                    required={true}
                />
                
                <Button className="mt-4" variant="contained" color="primary" type="submit">{loading ? <CircularProgress size='1.5rem' color='info' /> : 'SIGN IN'}</Button>
                   
                <Link
                    className="mt-4"
                    component="button"
                    variant="body2"
                    onClick={(event: any) => {event.preventDefault(); props.setSignUp(false)}}
                >
                    You don't have an account? Sign Up
                </Link>
            </div>

        </form>
    )
}