import { Autocomplete, Box, Button, IconButton, TextField, Tooltip, Typography } from "@mui/material";
import { GridCellEditCommitParams, GridCellParams, GridColDef, GridRenderCellParams, GridSortModel } from "@mui/x-data-grid";
import { DataGridPro } from "@mui/x-data-grid-pro";
import { useContext, useEffect, useState } from "react";
import toast from "react-hot-toast";
import { AuthContext } from "../../contexts/AuthContext";
import { PatchModel } from "../../models/patch.model";
import { Project } from "../../models/project.model";
import { SearchModel } from "../../models/search.model";
import { Task } from "../../models/task.model";
import { validateResponse } from "../../services/fetch.service";
import { getProjectsShort } from "../../services/project.service";
import { getTasks, patchTask, patchTaskInvoiced } from "../../services/task.service";
import { RenderInvoiced } from "./grid/RenderInvoiced";
import { RenderDate } from "../shared/ui/grid/RenderDate";
import { SearchInput } from "../shared/ui/SearchInput";
import { EditInvoiced } from "./grid/EditInvoiced";
import { EditProject } from "./grid/EditProject";
import { RenderUser } from "../shared/ui/grid/RenderUser";
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import { AddNewTask } from "./AddNewTask";
import { ConfirmModal } from "../shared/ui/modals/ConfirmModal";
import DeleteIcon from '@mui/icons-material/Delete';
import HighlightOffIcon from '@mui/icons-material/HighlightOff';
import { TaskFilters } from "./TaskFilters";
import { Filter } from "../../models/filter.model";
import { GlobalHotKeys } from "react-hotkeys";
import { CSVDownload } from "react-csv";
import FileDownloadIcon from '@mui/icons-material/FileDownload';
import { TASK_FILTERS } from "../../constants";
import { SetAllTasks } from "./SetAllTasks";
import { EditTime } from "./grid/EditTime";
import { TasksHours } from "../../models/tasksHours.model";

export function TasksList() {
    const {isAuthenticated, setAuthenticated } = useContext(AuthContext);
    const [tasks, setTasks] = useState<Task[]>([]);
    const [tasksHours, setTasksHours] = useState<TasksHours | null>(null);
    const [projects, setProjects] = useState<Project[]>([]);
    const [searchObj, setSearchObj] = useState<SearchModel>({page: 0, rowsPerPage: 50, search: "", orderBy: "date", asc: false});
    const [selectedProject, setSelectedProject] = useState<Project>();
    const [loading, setLoading] = useState<boolean>(false);
    const [total, setTotal] = useState(0);
    const [editCellObj, setEditCellObj] = useState<string>();
    const [initialized, setInitialized] = useState(false);
    const [newTaskModal, setNewTaskModal] = useState<boolean>(false);
    const [selectedObj, setSelectedObj] = useState<any>(null);
    const [selectedFilters, setSelectedFilters] = useState<Filter[]>([]);
    const [exportLoading, setExportLoading] = useState<boolean>(false);
    const [exportData, setExportData] = useState<any>(null);
    
    useEffect(() => {
        var defaultFilters: Filter[] = [];
        for(var i = 0; i < TASK_FILTERS.length; ++i) {
            for(var j = 0; j < TASK_FILTERS[i].filters.length; ++j) {
                if(TASK_FILTERS[i].filters[j].default) defaultFilters.push({...TASK_FILTERS[i].filters[j], category: TASK_FILTERS[i].category});
            }
        }
        setSelectedFilters(defaultFilters);
        retrieveTasks(defaultFilters);
        retrieveProjects();
    }, []);

    useEffect(() => {
        if(!initialized) return;
        const timeOutId = setTimeout(() => {setSearchObj({...searchObj, page: 0}); retrieveTasks()}, 500);
        return () => clearTimeout(timeOutId);
    }, [searchObj.search]);

    useEffect(() => {
        if(!initialized) return;
        setSearchObj({...searchObj, page: 0});
        retrieveTasks();
    }, [searchObj.rowsPerPage, searchObj.orderBy, searchObj.asc, selectedFilters, selectedProject]);

    useEffect(() => {
        if(!initialized) return;
        retrieveTasks();
    }, [searchObj.page]);

    const retrieveTasks = async(defaultFilters: Filter[] = []) => {
        setLoading(true);
        setTasksHours(null)
        try {
            const response = validateResponse(await getTasks({...searchObj, filters: selectedFilters.concat(defaultFilters).map((filter: Filter) => '&' + filter.field + '=' + filter.value).join("")}, selectedProject?.id), setAuthenticated);
            if(!response.error) {
                setInitialized(true);
                setTotal(response.total);
                setTasks(response.tasks);
                setTasksHours(response.tasksHours);
            } else {
                console.log('error inside');
            }
            setLoading(false);
        } catch(e) {
            console.log(e);
            setLoading(false)
        }
    }

    const updateTask = async(params: GridCellEditCommitParams) => {
        if(JSON.stringify(params.value) === editCellObj) return;
        try {
            const response = validateResponse(await patchTask(params as PatchModel), setAuthenticated);
            if(!response.error) {
                toast.success(`Task successfully ${params.field === "visible" && !params.value ? 'removed': 'updated'}.`);
            } else {
                toast.error("Error while updating the task.");
            }
        } catch(e) {
            console.log(e);
        }
    }

    const retrieveProjects = async() => {
        try {
            const response = validateResponse(await getProjectsShort(), setAuthenticated);
            if(!response.error) {
                setProjects(response.projects);
            } else {
                console.log('error inside');
            }
            setLoading(false);
        } catch(e) {
            console.log(e);
            setLoading(false);
        }
    }

    const addTask = async(task: Task) => {
        setTasks([task, ...tasks]);
    }

    const exportTasks = async() => {
        setExportLoading(true);
        try {
            const response = validateResponse(await getTasks({...searchObj, page: 0, rowsPerPage: total, filters: selectedFilters.map((filter: Filter) => '&' + filter.field + '=' + filter.value).join("")}, selectedProject?.id), setAuthenticated);
            if(!response.error) {
                setExportData(response.tasks.map((task: Task) => {
                    return ({Description: task.task, Project: task.project?.name, Time: task.time, TimeActual: task.timeActual, Date: task.date, Invoiced: task.invoiced})
                }));
                setTimeout(() => setExportData(null), 500);
            } else {
                console.log('error inside');
            }
            setExportLoading(false);
        } catch(e) {
            console.log(e);
            setExportLoading(false);
        }
    }
    
    const updateTasksInvoiced = async(newState: boolean) => {
        try {
            const response = validateResponse(await patchTaskInvoiced(newState, {...searchObj, page: 0, rowsPerPage: total, filters: selectedFilters.map((filter: Filter) => '&' + filter.field + '=' + filter.value).join(""), projectId: selectedProject?.id}), setAuthenticated);
            if(!response.error) {
                toast.success(`Tasks successfully updated`);
                setLoading(true);
                setTimeout(() => retrieveTasks(), 500);
            } else {
                toast.error("Error while updating the tasks.");
            }
            setSelectedObj(null);
        } catch(e) {
            console.log(e);
            setSelectedObj(null);
        }
    }

    const columns: GridColDef[] = [
        {
            field: "task",
            headerName: "What did you do?",
            editable: true,
            filterable: true,
            flex: 4,
        },
        {
            field: "project",
            headerName: "Project",
            editable: true,
            filterable: false,
            flex: 2,
            renderCell: (params: GridRenderCellParams<any, any, any>) => params.row.project?.name,
            renderEditCell: (params: GridRenderCellParams<any, any, any>) => <EditProject params={params} projects={projects}/>
        },
        {
            field: "time",
            headerName: `Invoicable ${tasksHours ? '(' + tasksHours.totalInvoicable?.toFixed(2).replace(/\.00$/, "") + ')' : ''}`,
            editable: true,
            filterable: true,
            flex: 1,
            type: "number",
            renderEditCell: (params: GridRenderCellParams<any, any, any>) => <EditTime {...params} />
        },
        {
            field: "timeActual",
            headerName: `Actual ${tasksHours ? '(' + tasksHours.totalActual?.toFixed(2).replace(/\.00$/, "") + ')' : ''}`,
            editable: true,
            filterable: true,
            flex: 1,
            type: "number",
            renderEditCell: (params: GridRenderCellParams<any, any, any>) => <EditTime {...params} />
        },
        {
            field: "date",
            headerName: "Date",
            editable: false,
            filterable: true,
            flex: 1,
            renderCell: (params: GridRenderCellParams<any, any, any>) => <RenderDate params={params} updateDate={updateTask}/>,
        },
        {
            field: "invoiced",
            headerName: "Invoiced",
            editable: true,
            filterable: true,
            // flex: 1,
            align: 'center',
            renderCell: (params: GridRenderCellParams<any, any, any>) => <RenderInvoiced {...params} />,
            renderEditCell: (params: GridRenderCellParams<any, any, any>) => <EditInvoiced {...params} />
        },
        {
            field: "user",
            headerName: "User",
            editable: false,
            filterable: false,
            sortable: false,
            renderCell: (params: GridRenderCellParams<any, any, any>) => <RenderUser {...params}/>,
        },
        {
            field: "edit",
            headerName: "",
            editable: false,
            sortable: false,
            filterable: false,
            disableColumnMenu: true,
            renderCell: (params: GridRenderCellParams<any, any, any>) => {
              return (
                <div key={params.row.id} className='flex gap-small'>      
                    <IconButton aria-label="delete" onClick={() => setSelectedObj(params.row)}>
                      <DeleteIcon />
                    </IconButton>
                </div>
              )
            }
        }
    ];
    
    return (
        <GlobalHotKeys
            keyMap={{
                FOCUS_BARCODE: "ctrl+enter"
            }}
            handlers={{
                FOCUS_BARCODE: () => setNewTaskModal(true)
            }}
        >
        <div>
            <div className='flex between items-start mb-3 mx-4'>
                <Tooltip title={'Ctrl + Enter'} placement="right">
                    <Button size='medium' variant="contained" color="primary" startIcon={<AddCircleOutlineIcon />} onClick={() => setNewTaskModal(true)}>
                        NEW TASK
                    </Button>
                </Tooltip>

                <Autocomplete
                    options={projects}
                    getOptionLabel={(option) => option.name}
                    value={selectedProject}
                    renderInput={(params: any) => <TextField  variant="filled" {...params} label="Search By Project" />}
                    onChange={(event: any, value: any) => {
                        setSelectedProject(value as Project);
                    }}
                    size='small'
                    
                    sx={{borderRadius: "20px !important", width: "250px"}}
                />
                <AddNewTask open={newTaskModal} projects={projects} setOpen={setNewTaskModal} addTask={addTask} />
                <ConfirmModal 
                        selectedObj={selectedObj} 
                        setSelectedObj={setSelectedObj} 
                        icon={<HighlightOffIcon color="error" sx={{ fontSize: "50px" }}/>} 
                        title="Confirm Delete"
                        message="Are you sure you want to delete the selected task?"
                        buttonText='Delete'
                        onConfirm={() => {
                            updateTask({id: selectedObj.id, field: "visible", value: false} as PatchModel); 
                            setSearchObj({...searchObj, page: 0});
                            setTasks(tasks.filter((task: Task) => task.id !== selectedObj?.id));
                            setTotal(total - 1);            
                            setSelectedObj(null);
                    }}/>  
            </div>        
            <div className="flex end gap-small mb-3 mx-4">
                <TaskFilters selectedFilters={selectedFilters} setSelectedFilters={setSelectedFilters} />
            </div>
            <Box className="relative" sx={{ width: '100%', height: "85vh", maxHeight: "85vh" }}>
                <DataGridPro
                    rows={tasks}
                    columns={columns}
                    rowCount={total}
                    page={searchObj.page}
                    loading={loading}
                    pageSize={searchObj.rowsPerPage}
                    onPageSizeChange={(newPageSize: number) => {
                    setSearchObj({...searchObj, page: 0, rowsPerPage: newPageSize})
                    }}
                    disableSelectionOnClick={true}
                    onPageChange={(page: number) => setSearchObj({...searchObj, page: page})}
                    rowsPerPageOptions={[5, 10, 20, 50, 100]}
                    pagination
                    paginationMode="server"
                    sortingMode="server"
                    sortModel={[{field: searchObj.orderBy ?? "", sort: searchObj.asc === true ? 'asc' : 'desc'}]}
                    onSortModelChange={(sortModel: GridSortModel) => {
                        if(sortModel[0] === undefined) {
                            setSearchObj({...searchObj, asc: true})
                        } else {
                            setSearchObj({...searchObj, orderBy: sortModel[0]?.field ?? "date", asc: (sortModel[0]?.sort == "asc" ? true : false)})
                        }
                    }}
                    onCellEditStart={(params: GridCellParams) => setEditCellObj(JSON.stringify(params.value))}
                    onCellEditCommit={updateTask}
                />
                <div className="absolute bottom items-center flex gap">
                    <Button  disabled={exportLoading} size='medium' variant="contained" color="primary" startIcon={<FileDownloadIcon />} onClick={exportTasks}>
                            EXPORT
                    </Button>
                    {
                        exportData && <CSVDownload data={exportData} target="_blank" />
                    }
                    {
                        selectedProject && <SetAllTasks tasks={tasks} onChange={updateTasksInvoiced} />
                    }
                </div>
            </Box>
        </div>

        </GlobalHotKeys>
    )
}