import { IconButton, Modal } from "@mui/material";
import { DivProps } from "react-html-props";
import CloseIcon from '@mui/icons-material/Close';

export function SimpleModal({open, background, setOpen, header, ...divProps}: SimpleModalProps) {
    return (
        setOpen 
        ?
        <Modal open={open} onClose={() => setOpen(false)} >
            <div className={`modal-content ${background === true ? 'background-grey': ''}`}>
                <div className="modal-close" onClick={() => setOpen(false)}>
                    <IconButton  aria-label="delete">
                        <CloseIcon />
                    </IconButton>
                </div>
                { divProps.children }
            </div>
        </Modal>
        :
        <Modal open={open}>
            <div>
                <>
                    {{...header} ?? ''}
                </>
                <div className={`modal-content ${background === true ? 'background-grey': ''}`}>
                    { divProps.children }
                </div>
            </div>
        </Modal>
    )
}

export interface SimpleModalProps extends DivProps {
    open: boolean;
    background?: boolean | undefined;
    setOpen?: React.Dispatch<React.SetStateAction<boolean>> | undefined;
    header?: React.DetailedHTMLProps<React.HTMLAttributes<HTMLDivElement>, HTMLDivElement> | undefined
}