import { FilterCategory } from "../models/filter.model";
import EventRepeatIcon from '@mui/icons-material/EventRepeat';
import TodayIcon from '@mui/icons-material/Today';
import CalendarMonthIcon from '@mui/icons-material/CalendarMonth';
import MoneyOffIcon from '@mui/icons-material/MoneyOff';
import PersonIcon from '@mui/icons-material/Person';
import AttachMoneyIcon from '@mui/icons-material/AttachMoney';
import DateRangeIcon from '@mui/icons-material/DateRange';

export const TASK_FILTERS: FilterCategory[] = [
    {
        category: "Person",
        filters: [
            {
                label: "Only Me",
                field: "onlyMe",
                value: true,
                icon: PersonIcon
            },
        ]
    },
    {
        category: "Invoice Status",
        filters: [
            {
                label: "Only Invoiced",
                field: "invoiceStatus",
                value: 1,
                icon: AttachMoneyIcon,
            },
            {
                label: "Not Invoiced",
                field: "invoiceStatus",
                value: 0,
                icon: MoneyOffIcon,
                default: true
            },
        ]
    },
    {
        category: "Dates",
        filters: [
            {
                label: "Today",
                field: "dateFilter",
                value: "today",
                icon: TodayIcon,
            },
            {
                label: "This Month",
                field: "dateFilter",
                value: "this_month",
                icon: DateRangeIcon,
            },
            {
                label: "Last Month",
                field: "dateFilter",
                value: "last_month",
                icon: EventRepeatIcon,
            }
        ]
    }
]