import { useContext, useEffect, useState } from "react";
import { AuthContext } from "../../contexts/AuthContext";
import { authStatus, updatePassword } from "../../services/auth.service";
import { SimpleModal } from "../shared/ui/modals/SimpleModal";
import { UpdatePassword } from "../../models/updatePassword.model";
import { TextField, Button, Alert } from "@mui/material";
import toast from "react-hot-toast";
import { validateResponse } from "../../services/fetch.service";
import React from "react";
import { WarningMessage } from "../../models/warningMessage.model";

export function MyAccount(props: {open: boolean, setOpen: React.Dispatch<React.SetStateAction<boolean>> }) {
    const {isAuthenticated, setAuthenticated } = useContext(AuthContext);
    const authContent = authStatus().content;

    const formDefault = {
        password: "",
        newPassword: "",
        newPasswordConfirm: ""
    } as UpdatePassword;

    const [form, setForm] = useState<UpdatePassword>(formDefault);
    const [topMessage, setTopMessage] = useState({error: false, message: null} as WarningMessage);

    useEffect(() => {
        if(topMessage.message) {
            setTimeout(() => setTopMessage({error: false, message: null}), 3000);
        }
    }, [topMessage]);
    
    const submit = async(e: any) => {
        if(!authContent?.id) return;
        e.preventDefault();
        const response = validateResponse(await updatePassword(form), setAuthenticated);
        try {
            if(!response.error) {
                setTopMessage({error: false, message: response.message});
                setForm(formDefault);
            } else {
                setTopMessage({error: true, message: response.error});
            }
        } catch(e: any) {
        }
    }

    return (
        <SimpleModal open={props.open} setOpen={props.setOpen}>
            <form onSubmit={submit}>
                <h3>UPDATE PASSWORD</h3>  
                {
                    topMessage.message !== null &&
                    <Alert className="mt-1 mb-2" variant="outlined" severity={topMessage.error ? 'error': 'success'}>
                        {topMessage.message}
                    </Alert>
                }
                <div className="flex column mt-4">
                    <TextField
                        label="Current Password"
                        required
                        type='password'
                        maxRows={1}
                        value={form.password}
                        autoFocus
                        onChange={(event: any) => setForm({...form, password: event.target.value})}
                    />
                    <TextField
                        className="mt-4"
                        label="New Password"
                        required
                        type={'password'}
                        maxRows={1}
                        value={form.newPassword}
                        onChange={(event: any) => setForm({...form, newPassword: event.target.value})}
                    />
                    <TextField
                        className="mt-2"
                        label="Confirm Password"
                        required
                        type={'password'}
                        maxRows={1}
                        value={form.newPasswordConfirm}
                        onChange={(event: any) => setForm({...form, newPasswordConfirm: event.target.value})}
                    />
                </div>
                
                <div className="flex end gap-small mt-4">
                    <Button className="" variant="outlined" color="secondary" onClick={() => props.setOpen(false)}>CANCEL</Button>
                    <Button className="" variant="contained" color="primary" type="submit" disabled={!form.password || !form.newPassword || !form.newPasswordConfirm || form.newPassword !== form.newPasswordConfirm}>UPDATE PASSWORD</Button>
                </div>
            </form>
        </SimpleModal>
    )
}