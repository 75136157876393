import { PatchModel } from "../models/patch.model";
import { Project } from "../models/project.model";
import { SearchModel } from "../models/search.model";

const API_URL = process.env.REACT_APP_API as String;

export const getProjects = async(searchModel: SearchModel): Promise<any> => {
    const requestOptions = {
        method: 'GET',
        headers: { 'Content-Type': 'application/json' },
        credentials: 'include' as any
    };
    return fetch(`${API_URL}projects?search=${searchModel?.search}&offset=${((searchModel?.page ?? 0) * (searchModel?.rowsPerPage ?? 100))}&limit=${searchModel?.rowsPerPage ?? 100}&orderBy=${searchModel?.orderBy}&asc=${searchModel?.asc}`, requestOptions)
           .then(async response => { return  {status: response.status, data: await response.json()}});
}

export const getProjectsShort = async(): Promise<any> => {
    const requestOptions = {
        method: 'GET',
        headers: { 'Content-Type': 'application/json' },
        credentials: 'include' as any
    };
    return fetch(`${API_URL}projects/short`, requestOptions)
           .then(async response => { return  {status: response.status, data: await response.json()}});
}

export const getProject = async(id: string): Promise<any> => {
    const requestOptions = {
        method: 'GET',
        headers: { 'Content-Type': 'application/json' },
        credentials: 'include' as any
    };
    return fetch(`${API_URL}projects/${id}`, requestOptions)
        .then(async response => { return  {status: response.status, data: await response.json()}});
}

export const patchProject = async(patch: PatchModel): Promise<any> => {
    const requestOptions = {
        method: 'PATCH',
        headers: { 'Content-Type': 'application/json' },
        credentials: 'include' as any,
        body: JSON.stringify(patch)
    };
    return fetch(`${API_URL}projects/${patch.id}`, requestOptions)
           .then(async response => { return  {status: response.status, data: await response.json()}});
}

export const putProject = async(projectPut: Project): Promise<any> => {
    const requestOptions = {
        method: 'PUT',
        headers: { 'Content-Type': 'application/json' },
        credentials: 'include' as any,
        body: JSON.stringify(projectPut)
    };
    return fetch(`${API_URL}projects/${projectPut.id}`, requestOptions)
           .then(async response => { return  {status: response.status, data: await response.json()}});
}

export const createProject = async(project: Project): Promise<any> => {
    const requestOptions = {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        credentials: 'include' as any,
        body: JSON.stringify(project)
    };
    return fetch(`${API_URL}projects/`, requestOptions)
           .then(async response => { return  {status: response.status, data: await response.json()}});
}

export const createInvoice = async(id: number): Promise<any> => {
    const requestOptions = {
        method: 'GET',
        headers: { 'Content-Type': 'application/json' },
        credentials: 'include' as any
    };
    return fetch(`${API_URL}projects/create-invoice/${id}`, requestOptions)
        .then(async response => { return  {status: response.status, data: await response.json()}});
}