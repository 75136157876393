import { Box, IconButton, Typography } from "@mui/material";
import { useContext, useState, useEffect } from "react";
import { AuthContext } from "../../../contexts/AuthContext";
import { Project } from "../../../models/project.model";
import { validateResponse } from "../../../services/fetch.service";
import { useNavigate, useParams } from "react-router";
import { getProject } from "../../../services/project.service";
import Tab from '@mui/material/Tab';
import { TabContext, TabList, TabPanel } from "@mui/lab";
import { ProjectSingleTasks } from "./ProjectSingleTasks";
import { ProjectSingleInfo } from "./ProjectSingleInfo";
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { ProjectSingleUtilization } from "./ProjectSingleUtilization";

export function ProjectSingle() {
    const {isAuthenticated, setAuthenticated } = useContext(AuthContext);
    const [project, setProject] = useState<Project>();
    const [tabIndex, setTabIndex] = useState('1');
    const { id } = useParams();
    const navigate = useNavigate();

    useEffect(() => {
        if(!id) return; 
        retrieveProject();
    }, []);

    const retrieveProject = async() => {
        if(!id) return;
        try {
            const response = validateResponse(await getProject(id), setAuthenticated);
            if(!response.error) {
                setProject(response.project);
            } else {
                console.log('error inside');
            }
        } catch(e) {
            console.log(e);
        }
    }

    return(
        <div className="">
            <div className="mx-4 mb-4">
                <div className="flex items-center start gap">
                    <IconButton aria-label="delete" size="large" onClick={() => navigate('/projects')}>
                        <ArrowBackIcon fontSize="inherit" />
                    </IconButton>
                    <div className="flex column">
                        <Typography className="mb-1" sx={{lineHeight: "22px"}} variant="h6" color="primary">
                            {project?.name}
                        </Typography>
                        <Typography sx={{lineHeight: "13px"}} color="secondary">
                            Project Detail
                        </Typography>
                    </div>
                </div>
            </div>
            <Box sx={{ width: '100%'}}>
                <TabContext value={tabIndex}>
                    <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                        <TabList onChange={(event: React.SyntheticEvent, newValue: string) => {setTabIndex(newValue)}}>
                            <Tab label="Tasks" value="1" />
                            <Tab label="Utilization" value="2" />
                            {/* <Tab label="Contacts" value="3" /> */}
                            <Tab label="Info" value="4" />
                        </TabList>
                    </Box>
                    <div className='flex w-100 between items-center mb-3'>
                        {
                            project && 
                            <div className="w-100">
                                <TabPanel value='1'>                        
                                    <ProjectSingleTasks project={project}/>
                                </TabPanel>
                                <TabPanel value='2'>                        
                                    <ProjectSingleUtilization project={project}/>
                                </TabPanel>
                                {/* <TabPanel value='3'>                        
                                    <ProjectSingleContact project={project}/>
                                </TabPanel> */}
                                <TabPanel  value='4'>                        
                                    <ProjectSingleInfo project={project} setProject={setProject}/>
                                </TabPanel>
                            </div>
                        }
                    </div>
                </TabContext>
            </Box>
        </div>
    )
}