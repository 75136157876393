import { ResponseModel } from "../models/response.model";

export function validateResponse(response: ResponseModel, setAuthenticated: (state: boolean) => void): any {
    switch (response.status) {
        case 200:
        case 201:
        case 400:
        case 409:
        case 500:
        default:
            return response.data;
        case 401:
        case 404:
            return window.location.replace("/");
        case 403:
            return setAuthenticated(false);
    }
}