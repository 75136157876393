import { SignInModel } from "../models/signin.model";
import { SignUpModel } from "../models/signup.model";
import jwt_decode from "jwt-decode";
import { UpdatePassword } from "../models/updatePassword.model";
import { TokenContent } from "../models/token.model";

const API_URL = process.env.REACT_APP_API as String;

export const authStatus= (): {authenticated: Boolean, quickbooksAuthenticated: Boolean, content?: TokenContent | null} => {
    let token = localStorage.getItem('token');
    let quickbooksTokenExpires = localStorage.getItem('quickbooksTokenExpires') 
    let isQuickbookExpired = (quickbooksTokenExpires ? Number(quickbooksTokenExpires): 0) > Date.now();

    if(!token) return {authenticated: false, quickbooksAuthenticated: isQuickbookExpired};
    let decodedToken = jwt_decode(token) as any;
    if(decodedToken.exp === undefined) return {authenticated: false, quickbooksAuthenticated: isQuickbookExpired};
    let currentDate = new Date();
    if (decodedToken.exp * 1000 < currentDate.getTime()) {
        return {authenticated: false, quickbooksAuthenticated: isQuickbookExpired};
    } else {
        return {authenticated: true, quickbooksAuthenticated: isQuickbookExpired, content: decodedToken};
    }
}

export const signin = async(model: SignInModel): Promise<any> => {
    const requestOptions = {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        credentials: 'include' as any,
        body: JSON.stringify(model)
    };

    return fetch(`${API_URL}auth/login`, requestOptions)
    .then(async response => { return  {status: response.status, data: await response.json()}});

}

export const signout = async(): Promise<any> => {
    const requestOptions = {
        method: 'GET',
        headers: { 'Content-Type': 'application/json' },
        credentials: 'include' as any
    };

    return fetch(`${API_URL}auth/logout`, requestOptions)
    .then(async response => { return  {status: response.status, data: await response.json()}});
}

export const refreshToken = async(): Promise<any> => {
    const requestOptions = {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        credentials: 'include' as any
    };

    return fetch(`${API_URL}auth/refresh`, requestOptions)
    .then(async response => { return  {status: response.status, data: await response.json()}});
}

export const updatePassword = async(model: UpdatePassword): Promise<any> => {
    const requestOptions = {
        method: 'PATCH',
        headers: { 'Content-Type': 'application/json' },
        credentials: 'include' as any,
        body: JSON.stringify(model)
    };

    return fetch(`${API_URL}auth/password`, requestOptions)
        .then(async response => { return  {status: response.status, data: await response.json()}});
}