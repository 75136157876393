import { Select, MenuItem, TextField, Autocomplete } from "@mui/material";
import { GridRenderCellParams } from "@mui/x-data-grid";
import { useGridApiContext } from "@mui/x-data-grid-pro";
import { Project } from "../../../models/project.model";

export function EditProject(props : {params: GridRenderCellParams<Project>, projects: Project[]}) {
    const { id, value, field } = props.params;
    const apiRef = useGridApiContext();

    return (
        <Autocomplete
            disablePortal
            fullWidth
            options={props.projects}
            getOptionLabel={(option) => option.name}
            value={value}
            renderInput={(params: any) => <TextField variant="outlined" {...params} label="" />}
            onChange={(event: any, value: any) => {
                apiRef.current.setEditCellValue({ id, field, value: value })
            }}
        />
    );
} 