import { createTheme } from '@mui/material/styles';

export const MANAGEBOOK_THEME = createTheme({
  palette: {
    primary: {
      light: '#707070',
      main: '#3d3d3d',
      dark: '#2b2b2b',
      contrastText: '#fff',
    },
    secondary: {
      light: '#b0b0b0',
      main: '#969696',
      dark: '#7d7d7d',
      contrastText: '#fff',
    },
    info: {
      light: '#ffffff',
      main: '#e0e0e0',
      dark: '#cccccc',
      contrastText: '#3d3d3d',
    },
    
  },
});