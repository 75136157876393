import { GridRenderCellParams } from "@mui/x-data-grid-pro";
import StarBorderIcon from '@mui/icons-material/StarBorder';
import StarIcon from '@mui/icons-material/Star';

export function RenderFavorite(props: {params: GridRenderCellParams<boolean>, onClick?: any}) {
    return (
        <span className={`favorite`} onClick={props.onClick}>
          {props.params.row.favorite == true ? <StarIcon/> : <StarBorderIcon/>}
        </span>
      )
}