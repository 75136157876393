import { Avatar, Tooltip } from "@mui/material";
import { fontSize } from "@mui/system";
import { GridRenderCellParams } from "@mui/x-data-grid-pro";
import { User } from "../../../../models/user.model";

function stringToColor(string: string) {
    let hash = 0;
    let i;
  
    for (i = 0; i < string.length; i += 1) {
      hash = string.charCodeAt(i) + ((hash << 5) - hash);
    }
    let color = '#';
    for (i = 0; i < 3; i += 1) {
      const value = (hash >> (i * 8)) & 0xff;
      color += `00${value.toString(16)}`.slice(-2);
    }
    return color;
  }

function stringAvatar(name: string) {
    return {
      sx: {
        bgcolor: stringToColor(name),
        width: 20, 
        height: 20,
        fontSize: "12px",
        color: "white",
        padding: "5px"
      },
      children: `${name.split(' ')[0][0]}${name.split(' ')[1][0]}`,
    };
}

export function RenderUser(params: GridRenderCellParams<User>) {
    if(params.row.user) {
        const userFullName = params.row.user?.firstName + " " + params.row.user?.lastName;
        return (
            <Tooltip title={userFullName}>
                <Avatar {...stringAvatar(userFullName)} />
            </Tooltip>
        )
    }
    return (
        <Avatar sx={{width: 30, height: 30}} />
    )
    
} 