import './App.css';
import { BrowserRouter } from "react-router-dom";
import { LicenseInfo } from '@mui/x-license-pro';
import { MANAGEBOOK_THEME } from './static/themes';
import { ThemeProvider } from '@emotion/react';
import { Root } from './pages/Root';
LicenseInfo.setLicenseKey('0f55b238c1326e938298a8b9087e8a26Tz01NDA4OCxFPTE2OTk2NDEyNjU0MzcsUz1wcm8sTE09c3Vic2NyaXB0aW9uLEtWPTI=');

function App() {
  return (
    <ThemeProvider theme={MANAGEBOOK_THEME}>
      <BrowserRouter>
        <Root/>
      </BrowserRouter>
    </ThemeProvider>
  );
}

export default App;