import { useNavigate } from "react-router"
import OpenInNewIcon from '@mui/icons-material/OpenInNew';

export function RenderOpen(props: {content: any, link: string}) {
    const navigate = useNavigate();
    return (
        <span className="flex items-center pointer render-open" onClick={() => navigate(props.link)}>
            {props.content}
            <OpenInNewIcon className="render-open-icon" />
        </span>
    )
}