import { TextField } from "@mui/material";
import { GridRenderCellParams, useGridApiContext } from "@mui/x-data-grid-pro";

export function EditTime(params: GridRenderCellParams<boolean>) {
    const { id, value, field } = params;
    const apiRef = useGridApiContext();

    return (
        <TextField
            type='number'
            inputProps={{step: "0.25"}}
            value={Number(value).toString()}
            onChange={(event: any) => apiRef.current.setEditCellValue({ id, field, value: event.target.value === "" ? 0 : Number(event.target.value)})}
        />
    );
} 