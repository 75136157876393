import { Button, Divider, ListItemIcon, ListItemText, Menu, MenuItem } from "@mui/material";
import { useState } from "react";
import { Filter, FilterCategory } from "../../models/filter.model";
import FilterAltIcon from '@mui/icons-material/FilterAlt';
import CancelIcon from '@mui/icons-material/Cancel';
import { TASK_FILTERS } from "../../constants";

export function TaskFilters(props: { selectedFilters: Filter[] , setSelectedFilters: React.Dispatch<React.SetStateAction<Filter[]>>}) {
    const {selectedFilters, setSelectedFilters} = props;
    const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
    const open = Boolean(anchorEl);
    
    const handleClick = (event: any) => {
      setAnchorEl(event.currentTarget);
    };
    
    const addFilter = (filter: Filter, category: string) => {
        setAnchorEl(null);
        if(selectedFilters.findIndex((selected: Filter) => selected.label === filter.label) !== -1) return;
        setSelectedFilters([...selectedFilters.filter((f: Filter) => f.category !== category), {...filter, category: category}]);
    };

    return (
        <div className="flex end items-center gap-small">
            <div className="flex gap-small">
            {
                selectedFilters.map((filter: Filter, index: number) => {
                    return (
                        <Button 
                            sx={{borderRadius: "20px"}} 
                            key={index} 
                            onClick={() => setSelectedFilters(selectedFilters.filter((f: Filter) => f.label !== filter.label))} 
                            variant="outlined"
                            color='primary'
                            startIcon={<CancelIcon />}>
                                {filter.label} <filter.icon sx={{fontSize: "15px"}}  className="ml-1"  />
                        </Button>
                    )
                })
            }
            </div>
            <div>
                <Button
                    sx={{borderRadius: "20px"}}
                    startIcon={<FilterAltIcon />}
                    onClick={handleClick}
                    variant='contained'
                >
                    Filters
                </Button>
                <Menu
                    anchorEl={anchorEl}
                    open={open}
                    onClose={() => setAnchorEl(null)}
                    anchorOrigin={{
                        vertical: 'top',
                        horizontal: 'left',
                    }}
                    transformOrigin={{
                        vertical: 'top',
                        horizontal: 'left',
                    }}
                    color='primary'
                >
                    {
                        TASK_FILTERS.map((FilterCategory: FilterCategory, index: number) => {
                            return FilterCategory.filters.map((filter: Filter, index2: number) => {
                                return (
                                    <div>
                                        { index > 0 && index2 === 0 && <Divider/>}
                                        <MenuItem color="secondary" key={index} onClick={() => addFilter(filter, FilterCategory.category)}>
                                            <ListItemIcon>
                                                <filter.icon/>
                                            </ListItemIcon>
                                            <ListItemText>{filter.label}</ListItemText>
                                        </MenuItem>
                                    </div>
                                )
                            })
                        })
                    }
                </Menu>
            </div>
        </div>
    )
}