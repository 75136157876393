import { LeftNav } from '../components/LeftNav';
import { Projects } from './projects/Projects';
import { Tasks } from './tasks/Tasks';
import { Users } from './users/Users';
import { Project } from './projects/Project';
import { UserSingle } from './users/UserSingle';
import { Login } from './auth/Login';
import { AuthContext } from '../contexts/AuthContext';
import { validateResponse } from '../services/fetch.service';
import { Dashboard } from './Dashboard';
import { authStatus, refreshToken, signout } from '../services/auth.service';
import { useContext, useEffect, useState } from 'react';
import { Route, Routes, Navigate, useNavigate } from "react-router-dom";
import { Toaster } from 'react-hot-toast';

export function Root() {
    const [isAuthenticated, setAuthenticated] = useState(authStatus().authenticated);
    const [hasAuthenticated, setHasAuthenticated] = useState(false);
    const [isQuickbooksAuthenticated, setQuickbooksAuthenticated] = useState(authStatus().quickbooksAuthenticated);

    const navigate = useNavigate();

    const refreshAuth = async () => {
      const response = validateResponse(await refreshToken(), setAuthenticated);
      if(!response.error) {
          localStorage.setItem('token', response?.token);
          localStorage.setItem('quickbooksTokenExpires', response?.quickbooksTokenExpires);
          setQuickbooksAuthenticated(authStatus().quickbooksAuthenticated);
      } else {}
    }
  
    useEffect(() => {
      if(isAuthenticated) {
        setHasAuthenticated(true);
        const intervalId = setInterval(async () => {
          refreshAuth();
        }, 300000);
        if(!authStatus().quickbooksAuthenticated) {
          refreshAuth();
        }
        return () => clearInterval(intervalId);
      } else if(hasAuthenticated) {
        signout();
        localStorage.removeItem('token');
        return navigate('/login');
      }
    }, [isAuthenticated])

    return (
        <AuthContext.Provider value={{isAuthenticated, setAuthenticated: setAuthenticated, isQuickbooksAuthenticated, setQuickbooksAuthenticated: setQuickbooksAuthenticated}}>
          <div className="admin-panel flex">
            <main className='flex'>
              { isAuthenticated && <LeftNav/>}
              <Routes>
                {
                  !isAuthenticated ?
                  <>
                    <Route path='/login' element={<Login/>} />
                    <Route path="*" element={<Navigate to="/login" />}/>
                  </>
                  :
                  <>
                    {/* <Route path="/" element={<Dashboard/>}  /> */}  
                   
                    <Route path='/projects' element={<Projects/>} />
                    <Route path="/projects/:id" element={<Project/>}  />

                    <Route path='/tasks' element={<Tasks/>} />

                     {/* <Route path='/users' element={<Users/>} /> */}
                    {/* <Route path="/users/:id" element={<UserSingle/>}  /> */}

                    <Route path="*" element={<Navigate to="/projects" />}/>
                  </>
                }
              </Routes>
            </main>
            <Toaster />
          </div>
        </AuthContext.Provider>
    )
}