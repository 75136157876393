import { Box, Button, Tooltip, Typography } from "@mui/material";
import { DataGridPro, GridCellEditCommitParams, GridCellParams, GridColDef, GridRenderCellParams, GridSortModel } from "@mui/x-data-grid-pro";
import { useContext, useEffect, useState } from "react";
import toast from "react-hot-toast";
import { AuthContext } from "../../contexts/AuthContext";
import { PatchModel } from "../../models/patch.model";
import { Project } from "../../models/project.model";
import { SearchModel } from "../../models/search.model";
import { authStatus } from "../../services/auth.service";
import { validateResponse } from "../../services/fetch.service";
import { getProjects, patchProject } from "../../services/project.service";
import { getUserFavorites, patchUser } from "../../services/user.service";
import { RenderStatus } from "../shared/ui/grid/status/RenderStatus";
import { EditStatus } from "../shared/ui/grid/status/EditStatus";
import { SearchInput } from "../shared/ui/SearchInput";
import { RenderFavorite } from "./grid/RenderFavorite";
import { RenderOpen } from "../shared/ui/grid/RenderOpen";
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import { AddNewProject } from "./AddNewProject";
import { GlobalHotKeys } from "react-hotkeys";


export function ProjectsList() {
    const {isAuthenticated, setAuthenticated } = useContext(AuthContext);
    const [projects, setProjects] = useState<Project[]>([]);
    const [searchObj, setSearchObj] = useState<SearchModel>({page: 0, rowsPerPage: 50, search: "", orderBy: "favorite", asc: false})
    const [loading, setLoading] = useState<boolean>(false);
    const [total, setTotal] = useState(0);
    const [editCellObj, setEditCellObj] = useState<string>();
    const [initialized, setInitialized] = useState(false);
    const [newProjectModal, setNewProjectModal] = useState(false);

    useEffect(() => {
        retrieveProjects();
    }, []);

    useEffect(() => {
        if(!initialized) return;
        const timeOutId = setTimeout(() => {setSearchObj({...searchObj, page: 0}); retrieveProjects()}, 500);
        return () => clearTimeout(timeOutId);
    }, [searchObj.search]);

    useEffect(() => {
        if(!initialized) return;
        setSearchObj({...searchObj, page: 0});
        retrieveProjects();
    }, [searchObj.rowsPerPage, searchObj.orderBy, searchObj.asc]);

    useEffect(() => {
        if(!initialized) return;
        retrieveProjects();
    }, [searchObj.page]);
  
    const retrieveProjects = async() => {
        setLoading(true);
        try {
            const response = validateResponse(await getProjects(searchObj), setAuthenticated);
            if(!response.error) {
                setTotal(response.total);
                setProjects(response.projects);
                setInitialized(true);
            } else {
                console.log('error inside');
            }
            setLoading(false);
        } catch(e) {
            console.log(e);
            setLoading(false);
        }
    }

    const updateProject = async(params: GridCellEditCommitParams) => {
        if(JSON.stringify(params.value) === editCellObj) return;
        try {
            const response = validateResponse(await patchProject(params as PatchModel), setAuthenticated);
            if(!response.error) {
                toast.success("Project successfully updated.");
            } else {
                toast.error("Error while updating the project.");
            }
        } catch(e) {
            console.log(e);
        }
    }

    const updateFavorites = async(projectParam: Project) => {
        if(!projectParam.id) return;
        try {
            const userFavoritesResponse =  validateResponse(await getUserFavorites(), setAuthenticated);
            if(!userFavoritesResponse.error) {
                var userFavorites: number[] = [...userFavoritesResponse.favorites];
                const alreadyFavorite = userFavorites.find((projectId: number) => projectId === projectParam.id);
                
                if(!alreadyFavorite) {
                    userFavorites = [...userFavorites, projectParam.id];
                } else {
                    userFavorites = userFavorites.filter((projectId: number) => projectId !== projectParam.id);
                }

                const userId = authStatus().content?.id;
                if(!userId) return;

                const response = validateResponse(await patchUser({
                    id: userId,
                    field: "favorites",
                    value: userFavorites.map((projectId: number) => { return {userId: userId, projectId: projectId}})
                } as PatchModel), setAuthenticated);

                if(!response.error) {
                    setProjects(projects.map((project: Project) => {
                        if(project.id === projectParam.id) {
                            return {...project, favorite: !alreadyFavorite};
                        }   
                        return project;
                    }));
                    toast.success(`Favorite ${alreadyFavorite ? "removed" : "added"}.`);
                } else {
                    toast.error("Error while updating the favorites.");
                }
            } else {
                toast.error("Error while updating the favorites.");
            }
        } catch(e) {
            console.log(e);
        }
    }

    const addProject = async(project: Project) => {
        setProjects([project, ...projects]);
    }

    const columns: GridColDef[] = [
        {
            field: "favorite",
            headerName: "",
            editable: false,
            sortable: false,
            filterable: false,
            renderCell: (params: any) => <RenderFavorite params={params} onClick={() => updateFavorites(params.row as Project)}/>,
        },
        {
            field: "name",
            headerName: "Project",
            editable: true,
            filterable: false,
            flex: 4,
            renderCell: (params: any) => <RenderOpen content={params.row.name} link={`/projects/${params.row.id}`}/>
        },
        {
            field: "priority",
            headerName: "Priority",
            editable: true,
            filterable: false,
            flex: 1,
        },
        {
            field: "hours",
            headerName: "Hours",
            editable: false,
            filterable: false,
            flex: 1,
        },
        {
            field: "due",
            headerName: "Due",
            editable: false,
            filterable: false,
            flex: 1,
            renderCell: (params: any) => (`${(params.row.due ? params.row.due: 0).toLocaleString('en-US', {style: 'currency',currency: 'USD'})}`)
        },
        {
            field: "utilization",
            headerName: "Utilization",
            editable: false,
            filterable: false,
            sortable: false,
            flex: 1,
            renderCell: (params: any) => (`${params.row.monthlyHours ?  params.row.hours + ' of ' + params.row.monthlyHours : '' }`)
        },
        {
            field: "active",
            headerName: "Status",
            editable: true,
            filterable: false,
            flex: 1,
            renderEditCell: (params: GridRenderCellParams<any, any, any>) => <EditStatus {...params} />,
            renderCell: (params: any) => <RenderStatus {...params} />
        },
      ];
    
    return (
        <GlobalHotKeys
            keyMap={{
                FOCUS_BARCODE: "ctrl+enter"
            }}
            handlers={{
                FOCUS_BARCODE: () => setNewProjectModal(true)
            }}
        >
        <div>
            <div className='flex end items-center mb-3 mx-4'>
                
                <AddNewProject open={newProjectModal} setOpen={setNewProjectModal} addProject={addProject}/>
                <SearchInput placeholder='Projects' value={searchObj.search ?? ""} setValue={(value: string) => setSearchObj({...searchObj, search: value})} />
            </div>        
            <Box className="relative" sx={{ width: '100%', height: "92.1vh", maxHeight: "92.1vh" }}>
                <DataGridPro
                    rows={projects}
                    columns={columns}
                    rowCount={total}
                    page={searchObj.page}
                    loading={loading}
                    pageSize={searchObj.rowsPerPage}
                    onPageSizeChange={(newPageSize: number) => {
                    setSearchObj({...searchObj, page: 0, rowsPerPage: newPageSize})
                    }}
                    disableSelectionOnClick={true}
                    onPageChange={(page: number) => setSearchObj({...searchObj, page: page})}
                    rowsPerPageOptions={[5, 10, 20, 50, 100]}
                    pagination
                    paginationMode="server"
                    sortingMode="server"
                    sortModel={[{field: searchObj.orderBy ?? "", sort: searchObj.asc === true ? 'asc' : 'desc'}]}
                    onSortModelChange={(sortModel: GridSortModel) => {
                        if(sortModel[0] === undefined) {
                            setSearchObj({...searchObj, asc: true})
                        } else {
                            setSearchObj({...searchObj, orderBy: sortModel[0]?.field ?? "priority", asc: (sortModel[0]?.sort == "asc" ? true : false)})
                        }
                    }}
                    onCellEditStart={(params: GridCellParams) => setEditCellObj(JSON.stringify(params.value))}
                    onCellEditCommit={updateProject}
                />
                <div className="absolute bottom items-center flex gap">
                    <Tooltip title={'Ctrl + Enter'} placement="right">
                        <Button size='medium' variant="outlined" color="primary" startIcon={<AddCircleOutlineIcon />} onClick={() => setNewProjectModal(true)}>
                            NEW PROJECT
                        </Button>
                    </Tooltip>
                </div>
            </Box>
        </div>

        </GlobalHotKeys>
        
    )
}