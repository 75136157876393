import { Autocomplete, Button, TextField } from "@mui/material";
import { useContext, useEffect, useState } from "react";
import { Project } from "../../models/project.model";
import { Task } from "../../models/task.model";
import { SimpleModal } from "../shared/ui/modals/SimpleModal";
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import dayjs from 'dayjs';
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { createTask } from "../../services/task.service";
import { validateResponse } from "../../services/fetch.service";
import { AuthContext } from "../../contexts/AuthContext";
import toast from "react-hot-toast";
import { authStatus } from "../../services/auth.service";

export function AddNewTask(props: {open: boolean, setOpen: React.Dispatch<React.SetStateAction<boolean>>, addTask: (task: Task) => Promise<void>, projects?: Project[] | undefined, project?: Project | undefined}) {
    const {isAuthenticated, setAuthenticated } = useContext(AuthContext);

    const formDefault = {
        task: "",
        date: new Date(),
        time: 0,
        timeActual: 0,
        invoiced: false,
        priority: 0,
        project: props.project
    } as Task;

    const [form, setForm] = useState<Task>(formDefault);

    const submit = async(e: any) => {
        e.preventDefault();
        const response = validateResponse(await createTask(form), setAuthenticated);
        try {
            if(!response.error) {
                const jwtContent = authStatus();
                props.addTask({...form, id: response.taskId, user: {id: jwtContent?.content?.id, firstName: jwtContent?.content?.firstname, lastName: jwtContent?.content?.lastname}} as Task)
                props.setOpen(false);
                toast.success("Task successfully added.");
                setForm(formDefault);
            } else {
                toast.error("Task couldn't be added.");
            }
        } catch(e: any) {
        }
    }

    useEffect(() => {
        if(form.timeActual < form.time) setForm({...form, timeActual: form.time}); 
    }, [form.time])

    return (
        <SimpleModal open={props.open} setOpen={props.setOpen}>
            <form onSubmit={submit}>
                <h3>New Task</h3>  
                <div className="mt-4">
                    <TextField
                        fullWidth
                        label="What did you do?"
                        multiline
                        required
                        maxRows={4}
                        value={form.task}
                        autoFocus
                        onChange={(event: any) => setForm({...form, task: event.target.value})}
                    />
                </div>
                {
                    props.projects &&
                    <div className="mt-3 flex gap">
                        <Autocomplete
                            disablePortal
                            fullWidth
                            options={props.projects}
                            getOptionLabel={(option) => option.name}
                            renderInput={(params: any) => <TextField {...params} label="Project" />}
                            onChange={(event: any, value: any) => {
                                setForm({...form, project: value})
                            }}
                        />
                    </div>
                }
                <div className="mt-3 flex gap">
                    <TextField
                        sx={{ width: "100px"}}
                        label="Invoicable"
                        type='number'
                        required
                        inputProps={{step: "0.25"}}
                        value={Number(form.time).toString()}
                        onChange={(event: any) => setForm({...form, time: event.target.value === "" ? 0 : Number(event.target.value)})}
                    />
                    <TextField
                        sx={{ width: "100px"}}
                        label="Actual"
                        type='number'
                        required
                        inputProps={{step: "0.25"}}
                        value={Number(form.timeActual).toString()}
                        onChange={(event: any) => setForm({...form, timeActual: event.target.value === "" ? 0 : Number(event.target.value)})}
                    />
                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                        <DatePicker
                        label="Date"
                        value={form.date}
                        onChange={(newValue: any) => {
                            if(!newValue) return;
                            const newDate = dayjs(newValue);
                            setForm({...form, date: newDate.toDate()})
                        }}
                        renderInput={(params) => <TextField  {...params} />}
                        />
                    </LocalizationProvider>
                </div>
                <div className="flex end gap-small mt-4">
                    <Button className="" variant="outlined" color="secondary" onClick={() => props.setOpen(false)}>CANCEL</Button>
                    <Button className="" variant="contained" color="primary" type="submit" disabled={!form.project || !form.task?.length}>SAVE TASK</Button>
                </div>
            </form>
        </SimpleModal>
    )
}