import { Button, Menu, MenuItem, ListItemIcon, ListItemText } from "@mui/material";
import { useContext, useEffect, useState } from "react";
import { SET_ALL_TYPES } from "../../constants";
import { SetAllType } from "../../models/setAllType";
import { Task } from "../../models/task.model";
import AutoFixHighIcon from '@mui/icons-material/AutoFixHigh';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import { ConfirmModal } from "../shared/ui/modals/ConfirmModal";
import { AuthContext } from "../../contexts/AuthContext";

export function SetAllTasks(props: {tasks: Task[], onChange: any}) {
    const {isAuthenticated, setAuthenticated } = useContext(AuthContext);
    const {tasks, onChange} = props;
    const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
    const open = Boolean(anchorEl);
    const [openConfirmModal, setOpenConfirmModal] = useState(false);
    const [selectedObj, setSelectedObj] = useState<SetAllType|null>(null);

    useEffect(() => {
        if(!selectedObj) return;
        setAnchorEl(null);
        setOpenConfirmModal(true);
    }, [selectedObj])
    
    
    const handleClick = (event: any) => {
      setAnchorEl(event.currentTarget);
    };
    
    return (
        <div className="flex end items-center gap-small">
            <div>
                <Button
                    sx={{textTransform: "capitalize", fontSize: "12px", padding: "2px 10px"}}
                    // startIcon={<AutoFixHighIcon  />}
                    onClick={handleClick}
                    variant='outlined'
                    size="small"
                    color="primary"
                    endIcon={<KeyboardArrowDownIcon />}
                >
                    Edit all
                </Button>
                <Menu

                    anchorEl={anchorEl}
                    open={open}
                    onClose={() => setAnchorEl(null)}
                    anchorOrigin={{
                        vertical: 'top',
                        horizontal: 'left',
                    }}
                    transformOrigin={{
                        vertical: 'top',
                        horizontal: 'left',
                    }}
                    color='primary'
                >
                    {
                        SET_ALL_TYPES.map((type: SetAllType, index: number) => {
                            return (
                                <MenuItem color="secondary" key={index} onClick={() => setSelectedObj(type)}>
                                    <ListItemIcon>
                                        <type.icon/>
                                    </ListItemIcon>
                                    <ListItemText>{type.label}</ListItemText>
                                </MenuItem>
                            )
                        })
                    }
                </Menu>
            </div>
            {
                selectedObj &&
                <ConfirmModal 
                    selectedObj={selectedObj} 
                    setSelectedObj={setSelectedObj} 
                    icon={<AutoFixHighIcon color="error" sx={{ fontSize: "50px" }}/>} 
                    title="Confirm Bulk Update"
                    message={selectedObj.confirmMessage}
                    buttonText='UPDATE ALL'
                    onConfirm={() => {
                        onChange(selectedObj.value);
                        setSelectedObj(null);
                }}/>  
            }
        </div>
    )
}