import { ProjectSingle } from "../../components/projects/Single/ProjectSingle";

export function Project() {


    return (
        <div>
            <ProjectSingle/>
        </div>
    )
}