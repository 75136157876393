import { PatchModel } from "../models/patch.model";
import { SearchModel } from "../models/search.model";
import { SignUpModel } from "../models/signup.model";
import { User } from "../models/user.model";

const API_URL = process.env.REACT_APP_API as String;

export const getUsers = async(searchModel: SearchModel, accountIds?: string[]): Promise<any> => {
    const requestOptions = {
        method: 'GET',
        headers: { 'Content-Type': 'application/json' },
        credentials: 'include' as any
    };
    return fetch(`${API_URL}users?search=${searchModel?.search}&offset=${((searchModel?.page ?? 0) * (searchModel?.rowsPerPage ?? 100))}&limit=${searchModel?.rowsPerPage ?? 100}&orderBy=${searchModel?.orderBy}&asc=${searchModel?.asc}&accounts=${accountIds}`, requestOptions)
           .then(async response => { return  {status: response.status, data: await response.json()}});
}

export const getUser = async(id: string): Promise<any> => {
    const requestOptions = {
        method: 'GET',
        headers: { 'Content-Type': 'application/json' },
        credentials: 'include' as any
    };
    return fetch(`${API_URL}users/${id}`, requestOptions)
           .then(async response => { return  {status: response.status, data: await response.json()}});
}

export const createUser = async(model: SignUpModel): Promise<any> => {
    const requestOptions = {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(model)
    };

    return fetch(`${API_URL}users`, requestOptions)
    .then(async response => { return  {status: response.status, data: await response.json()}});
}

export const patchUser = async(patch: PatchModel): Promise<any> => {
    const requestOptions = {
        method: 'PATCH',
        headers: { 'Content-Type': 'application/json' },
        credentials: 'include' as any,
        body: JSON.stringify(patch)
    };
    return fetch(`${API_URL}users/${patch.id}`, requestOptions)
           .then(async response => { return  {status: response.status, data: await response.json()}});
}

export const putUser = async(userPut: User): Promise<any> => {
    const requestOptions = {
        method: 'PUT',
        headers: { 'Content-Type': 'application/json' },
        credentials: 'include' as any,
        body: JSON.stringify(userPut)
    };
    return fetch(`${API_URL}users/${userPut.id}`, requestOptions)
           .then(async response => { return  {status: response.status, data: await response.json()}});
}

export const getUserFavorites = async(): Promise<any> => {
    const requestOptions = {
        method: 'GET',
        headers: { 'Content-Type': 'application/json' },
        credentials: 'include' as any
    };
    return fetch(`${API_URL}users/favorites`, requestOptions)
           .then(async response => { return  {status: response.status, data: await response.json()}});
}