import { StaticTimePicker } from "@mui/x-date-pickers";
import { PatchModel } from "../models/patch.model";
import { Project } from "../models/project.model";
import { SearchModel } from "../models/search.model";
import { Task } from "../models/task.model";

const API_URL = process.env.REACT_APP_API as String;

export const getTasks = async(searchModel: SearchModel, projectId?: number | undefined): Promise<any> => {
    const requestOptions = {
        method: 'GET',
        headers: { 'Content-Type': 'application/json' },
        credentials: 'include' as any
    };
    return fetch(`${API_URL}tasks?search=${searchModel?.search}&offset=${((searchModel?.page ?? 0) * (searchModel?.rowsPerPage ?? 100))}&limit=${searchModel?.rowsPerPage ?? 100}&orderBy=${searchModel?.orderBy}&asc=${searchModel?.asc}${projectId ? '&projectId=' + projectId : ''}${searchModel.filters ?? ''}`, requestOptions)
           .then(async response => { return  {status: response.status, data: await response.json()}});
}


export const patchTask = async(patch: PatchModel): Promise<any> => {
    const requestOptions = {
        method: 'PATCH',
        headers: { 'Content-Type': 'application/json' },
        credentials: 'include' as any,
        body: JSON.stringify(patch)
    };
    return fetch(`${API_URL}tasks/${patch.id}`, requestOptions)
           .then(async response => { return  {status: response.status, data: await response.json()}});
}

export const createTask = async(task: Task): Promise<any> => {
    const requestOptions = {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        credentials: 'include' as any,
        body: JSON.stringify(task)
    };
    return fetch(`${API_URL}tasks/`, requestOptions)
        .then(async response => { return  {status: response.status, data: await response.json()}});
}

export const patchTaskInvoiced = async(state: boolean, searchModel: any): Promise<any> => {
    const requestOptions = {
        method: 'PATCH',
        headers: { 'Content-Type': 'application/json' },
        credentials: 'include' as any,
        body: JSON.stringify({state: state, ...searchModel})
    };
    return fetch(`${API_URL}tasks/bulk/invoiced?${searchModel.filters}`, requestOptions)
           .then(async response => { return  {status: response.status, data: await response.json()}});
}