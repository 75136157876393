import { SetAllType } from "../models/setAllType"
import AttachMoneyIcon from '@mui/icons-material/AttachMoney';
import MoneyOffIcon from '@mui/icons-material/MoneyOff';

export const ALL_TYPES = {
    invoiced: 'invoiced',
}

export const SET_ALL_TYPES: SetAllType[] = [
    {
        type: ALL_TYPES.invoiced,
        value: true,
        label: 'Set as invoiced',
        confirmMessage: "You are about to set to INVOICED all the filtered tasks. Are you sure?",
        icon: AttachMoneyIcon,

    }, 
    {
        type: ALL_TYPES.invoiced,
        value: false,
        label: 'Set not invoiced',
        confirmMessage: "You are about to set to NOT INVOICED all the filtered tasks. Are you sure?",
        icon:MoneyOffIcon
    }
]