import { Checkbox } from "@mui/material";
import { GridRenderCellParams } from "@mui/x-data-grid";
import { useGridApiContext } from "@mui/x-data-grid-pro";

export function EditInvoiced(params: GridRenderCellParams<boolean>) {
    const { id, value, field } = params;
    const apiRef = useGridApiContext();

    return (
        <Checkbox 
            checked={value}
            onChange={(event: any) => {
                apiRef.current.setEditCellValue({ id, field, value: event.target.checked });
            }}
        />
    );
} 