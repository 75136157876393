import { Button, IconButton, Modal, Typography } from "@mui/material";
import CloseIcon from '@mui/icons-material/Close';

export function ConfirmModal({selectedObj, setSelectedObj, title, message, buttonText, onConfirm, icon}: ConfirmModalProps) {
    return (
        <Modal open={selectedObj !== null} onClose={() => setSelectedObj(null)} closeAfterTransition>
            <div className="modal-content flex column items-center smaller">
                <div className="modal-close" onClick={() => setSelectedObj(null)}>
                    <IconButton  aria-label="delete">
                        <CloseIcon />
                    </IconButton>
                </div>
                {icon !== undefined ? icon : ''} 
                <Typography id="spring-modal-title" variant="h6" component="h2">
                    {title}
                </Typography>
                <Typography id="spring-modal-description" sx={{ mt: 2, textAlign: 'center' }}>
                    {message}
                </Typography>
                <div className='flex gap-small center mt-5'>
                    <Button variant="outlined" onClick={() => setSelectedObj(null)} >Cancel</Button>
                    <Button variant="contained" color='error' onClick={onConfirm}>{buttonText}</Button>
                </div>
            </div>
        </Modal>
    )
}

export interface ConfirmModalProps {
    selectedObj: any;
    setSelectedObj: React.Dispatch<any>;
    icon?: any | undefined;
    title: string,
    message: string;
    buttonText: string;
    onConfirm: () => void;
}